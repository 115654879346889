import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Scott Garrison's Netlify Build</h1>
		<img src="images/tenor.gif" alt="ralph wiggum waving" />
      </header>
    </div>
  );
}

export default App;
